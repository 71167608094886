import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AiOutlineCheck } from "react-icons/ai";

const Edit = () => {
    const { id } = useParams()
    const [todo, setTodo] = useState('')
    const [editTodo, setEditTodo] = useState('')

    const edit = () => {
        if(editTodo.length > 0) {
            fetch(`api/edit/${id}`, {
                method: 'POST',
                body: JSON.stringify({
                    id: id,
                    content: editTodo
                })
            }).then(response => response.json()).then(message => {
                setTodo(editTodo)
                setEditTodo(editTodo)
            }
            )
        }
    }

    const handleChangeForm = (event) => {
        setEditTodo(event.target.value)
    }

    useEffect(() => {
        fetch(`/api/${id}`)
        .then(response => response.json())
        .then(data => {
            setTodo(data[0].content)
            setEditTodo(data[0].content)
        })
    }, [id])

    return(
        <div className='edit' >
            <p className='edit__content' >{todo}</p>
            <p className='edit__form'>
                <input className='edit__text' type='text' value={editTodo} onChange={handleChangeForm}></input>
                <button className='edit__button' onClick={edit}><AiOutlineCheck/></button>
            </p>
        </div>
    )
}

export default Edit