import React from 'react'
import {Delete} from './Delete'
import {Link} from 'react-router-dom'
import { AiFillEdit } from 'react-icons/ai#AiOutlinePlus'

export const Card = ( {todoItems, updateData}) => {
    return(
    <>
    <ul className='todos'>
        {todoItems.map(todo => {
        return(
        <li className='todos__item' key={todo.id}>
         <p className='todos__content'> {todo.content} </p>
            <Link className='todos__link' to={`${todo.id}`}>
            <AiFillEdit />
            </Link>
            <Delete todoId={todo.id} updateData={updateData}/>
        </li>
        )
        })}
    </ul>
    </>
    )
}
