import React from 'react'
import TodoList from './pages/TodoList'
import './App.scss'
import TodoItem from './pages/TodoItem'
import {
  BrowserRouter as Router,
  Routes, // instead of "Switch"
  Route,
  Link,
} from "react-router-dom"

const App = () => {
  return(
    <div className='app'>
      <h1>Todo App</h1>
    <Router>
        <Routes>
            <Route exact path='/' element={<TodoList />}/>
            <Route path='/:id' element={<TodoItem />}/>
        </Routes> 
    </Router>
    </div>
  )
} 

export default App