import React from 'react'
import { AiOutlineDelete }from 'react-icons/ai#AiOutlinePlus';
import TodoList from '../pages/TodoList';

export const Delete = ({todoId, updateData}) => {
    const deleteTodo = () => {
        fetch('api/delete', {
            method: 'POST',
            body: JSON.stringify({
                id: todoId
            })
        }).then(response => {
            response.json()
            update()
        })
    }

    const update = () => {
        fetch('/api').then(response => {
            if(response.ok) {
                return response.json()
            }
        }).then(data => updateData(data))
    }

    return(
        <>
            <button className='todos__button' onClick={deleteTodo}><AiOutlineDelete /></button>
        </>
    )
}
