import React from 'react'
import Edit from '../components/Edit'
import { Link } from "react-router-dom";

const TodoItem = () => {
    return(
        <>
        <Edit />
        <Link className='link__back' to="/">Back</Link>
        </>
    )
}

export default TodoItem