import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai#AiOutlinePlus';

export const Add = ({userInput, changeForm, set, updateData}) => {
    const handleChange = (event) => {
        changeForm(event.target.value)
    }

    const addTodo = () => {
        if(userInput.length > 0){
            fetch('api/create', {
                method: 'POST',
                body: JSON.stringify({
                    content: userInput
                })
            }).then(response => {
                response.json()
                set('')
                update()
            })
        }
    }

    const update = () => {
        fetch('/api').then(response => {
            if(response.ok) {
                return response.json()
            }
        }).then(data => updateData(data))
    }

    return(
        <form className='form'>
            <input className='form__text' type="text" onChange={handleChange} value={userInput} placeholder="Add your new Todo"></input>
            <button className='form__button' onClick={addTodo}><AiOutlinePlus/></button>
        </form>
    )
}