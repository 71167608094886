import React, {useState, useEffect} from 'react'
import {Card} from '../components/Card'
import {Add} from '../components/Add'

const TodoList = () => {
    const [todo, setTodo] = useState([])
    const [addTodo, setAddTodo] = useState('')

    useEffect(() => {
        fetch('/api').then(response => {
            if(response.ok){
                return response.json()
            }
        }).then(data => setTodo(data))
    }, [])

    const handleChangeForm = (inputValue) => {
        setAddTodo(inputValue)
    }

    return(
        <>
            <Add userInput={addTodo} changeForm={handleChangeForm} set={setAddTodo} updateData={setTodo}/>
            <Card todoItems={todo} updateData={setTodo} />
        </>
    )
}

export default TodoList